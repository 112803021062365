import { createRouter, createWebHistory } from 'vue-router';

const HomeComponent = () => import('./components/HomeComponent.vue');
const ContactComponent = () => import('./components/ContactComponent.vue');
const TeamComponent = () => import('./components/TeamComponent.vue');
const AboutComponent = () => import('./components/AboutComponent.vue');
const ServicesComponent = () => import('./components/ServicesComponent.vue');
const CoursesComponent = () => import('./components/CoursesComponent.vue');
const BlogComponent = () => import('./components/BlogComponent.vue');

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeComponent
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactComponent
  },
  {
    path: '/team',
    name: 'Team',
    component: TeamComponent
  },
  {
    path: '/about',
    name: 'About',
    component: AboutComponent
  },
  {
    path: '/services',
    name: 'Service',
    component: ServicesComponent
  },
  {
    path: '/courses',
    name: 'Courses',
    component: CoursesComponent
  },
  {
    path: '/blog',
    name: 'Blog',
    component: BlogComponent
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  // Check if the page has already reloaded
  if (!sessionStorage.getItem('reloaded')) {
    // Only reload if navigating to a specific path (if needed)
    if (to.path === '/your-image-path') { // Adjust this path as necessary
      sessionStorage.setItem('reloaded', 'true');

      // Delay the reload slightly to allow the route change to complete
      setTimeout(() => {
        window.location.reload();  // Perform full page reload
      }, 100);  // Adjust the delay if needed
    } else {
      next(); // Proceed normally for other routes
    }
  } else {
    sessionStorage.removeItem('reloaded'); // Clear the flag for subsequent visits
    next(); // Allow normal navigation
  }
});

export default router;
