<template>
  <div>

  <!-- preloader -->
  <div class="preloader">
    <div id="handle-preloader" class="handle-preloader">
        <div class="animation-preloader">
            <div class="spinner"></div>
            <div class="txt-loading">
                <span data-text-preloader="N" class="letters-loading">
                    N
                </span>
                <span data-text-preloader="E" class="letters-loading">
                    E
                </span>
                <span data-text-preloader="X" class="letters-loading">
                    X
                </span>
                <span data-text-preloader="T" class="letters-loading">
                    T
                </span>
                <span data-text-preloader="G" class="letters-loading">
                    G
                </span>
                <span data-text-preloader="E" class="letters-loading">
                    E
                </span>
                <span data-text-preloader="N" class="letters-loading">
                    N
                </span>
            </div>
        </div>  
    </div>
</div>
<!-- preloader end -->

 <!-- search-popup -->
 <div id="search-popup" class="search-popup">
  <div class="close-search"><span>Close</span></div>
  <div class="popup-inner">
      <div class="overlay-layer"></div>
      <div class="search-form">
          <form method="post" action="/">
              <div class="form-group">
                  <fieldset>
                      <input type="search" class="form-control" name="search-input" value="" placeholder="Search Here" required >
                      <input type="submit" value="Search Now!" class="theme-btn style-four">
                  </fieldset>
              </div>
          </form>
          <h3>Recent Search Keywords</h3>
          <ul class="recent-searches">
              <li><a href="/">Finance</a></li>
              <li><a href="/">Idea</a></li>
              <li><a href="/">Service</a></li>
              <li><a href="/">Growth</a></li>
              <li><a href="/">Plan</a></li>
          </ul>
      </div>
  </div>
</div>
<!-- search-popup end -->


   <!-- main header -->
<header class="main-header style-three">
  <div class="header-upper">
      <div class="outer-box clearfix">
          <div class="left-column pull-left clearfix">
              <div class="logo-box pull-left">
                  <figure class="logo"><router-link to="/"><img src="assets/images/logo5.png" alt=""></router-link></figure>
              </div>
              <div class="menu-area pull-right clearfix">
                  <!--Mobile Navigation Toggler-->
                  <div class="mobile-nav-toggler">
                      <i class="icon-bar"></i>
                      <i class="icon-bar"></i>
                      <i class="icon-bar"></i>
                  </div>
                  <nav class="main-menu navbar-expand-md navbar-light">
                      <div class="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                          <ul class="navigation clearfix">
                              <li><router-link to="/">Home</router-link> </li> 
                              <li class="dropdown"><router-link to="/courses">Courses</router-link></li>
                              <li class="dropdown"><router-link to="/services">Our Services</router-link></li>
                              <li class="dropdown"><router-link to="/blog">Blog</router-link></li>
                              <li class="dropdown"><router-link to="/team">Our Team</router-link></li>
                              <li class="dropdown"><router-link to="/about">About</router-link></li>

                              <!-- <li class="dropdown"><a href="/">About</a>
                                  <ul>
                                    <li><router-link to="/about">About Company<i class="flaticon-next"></i></router-link></li>
                                    <li><router-link to="/team">Meet Our Team<i class="flaticon-next"></i></router-link></li>
                                  </ul>
                              </li> -->
                              <li class="dropdown"><router-link to="/contact">Contact</router-link></li>
                          </ul>
                      </div>
                  </nav>
                  <div class="search-box-outer">
                      <form action="/" method="post" class="search-btn">
                          <button type="button" class="search-toggler"><i class="flaticon-search"></i>Search</button>
                      </form>
                  </div>
              </div>
          </div>
          <div class="right-column pull-right clearfix">
              <div class="btn-box"><router-link to="contact" class="theme-btn style-six">Get in Touch</router-link></div>             
              <ul class="social-links clearfix">
                  <li><a href="/"><i class="fab fa-google-plus-g"></i></a></li>
                  <li><a href="/"><i class="fab fa-twitter"></i></a></li>
                  <li><a href="/"><i class="fab fa-facebook-f"></i></a></li>
              </ul>
          </div>
      </div>
  </div>

  <!--sticky Header-->
  <div class="sticky-header">
      <div class="outer-box clearfix">
          <div class="left-column pull-left">
              <figure class="logo-box"><a href="/"><img src="assets/images/logo2.png" alt=""></a></figure>
              <div class="menu-area">
                  <nav class="main-menu clearfix">
                      <!--Keep This Empty / Menu will come through Javascript-->
                  </nav>
                  <div class="search-box-outer">
                      <form action="/" method="post" class="search-btn">
                          <button type="button" class="search-toggler"><i class="flaticon-search"></i>Search</button>
                      </form>
                  </div>
              </div>
          </div>
          <div class="right-column pull-right clearfix">
              <div class="btn-box"><router-link to="contact" class="theme-btn style-six">Get in Touch</router-link></div>
              <!-- <div class="language">
                  <div class="lang-btn">
                      <span class="flag"><img src="assets/images/icons/icon-lang.png" alt="" title="English"></span>
                      <span class="txt">Eng</span>
                      <span class="arrow fa fa-angle-down"></span>
                  </div>
                  <div class="lang-dropdown">
                      <ul>
                          <li><a href="/">German</a></li>
                          <li><a href="/">Italian</a></li>
                          <li><a href="/">Chinese</a></li>
                          <li><a href="/">Russian</a></li>
                      </ul>
                  </div>
              </div> -->
              <ul class="social-links clearfix">
                  <li><a href="/"><i class="fab fa-google-plus-g"></i></a></li>
                  <li><a href="/"><i class="fab fa-twitter"></i></a></li>
                  <li><a href="/"><i class="fab fa-facebook-f"></i></a></li>
              </ul>
          </div>
      </div>
  </div>
</header>
<!-- main-header end -->

<!-- Mobile Menu  -->
<div class="mobile-menu">
  <div class="menu-backdrop"></div>
  <div class="close-btn"><i class="fas fa-times"></i></div>
  
  <nav class="menu-box">
      <div class="nav-logo"><a href="/"><img src="assets/images/logo5.png" alt="" title=""></a></div>
      <div class="menu-outer"><!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header--></div>
      <div class="contact-info">
          <h4>Contact Info</h4>
          <ul>
              <li>Westland - Nairobi, Kenya</li>
              <li><a href="tel:+8801682648101">+254 734 606 021</a></li>
              <li><a href="mailto:info@example.com">info@nextgendata.co.ke</a></li>
          </ul>
      </div>
      <div class="social-links">
          <ul class="clearfix">
              <li><a href="/"><span class="fab fa-twitter"></span></a></li>
              <li><a href="/"><span class="fab fa-facebook-square"></span></a></li>
              <li><a href="/"><span class="fab fa-pinterest-p"></span></a></li>
              <li><a href="/"><span class="fab fa-instagram"></span></a></li>
              <li><a href="/"><span class="fab fa-youtube"></span></a></li>
          </ul>
      </div>
  </nav>
</div><!-- End Mobile Menu -->
  </div>
</template>

<script>
export default {
  name: "HeaderComponent",
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>