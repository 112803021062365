import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

// Import jQuery
import $ from 'jquery';

// Import stylesheets
import './assets/css/font-awesome-all.css';
import './assets/css/flaticon.css';
import './assets/css/bootstrap.css';
import './assets/css/jquery.fancybox.min.css';
import './assets/css/animate.css';
import './assets/css/imagebg.css';
import './assets/css/global.css';
import './assets/css/header.css';
import './assets/css/color.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import './assets/css/owl.css';

// Function to dynamically load scripts in correct order
function loadScript(src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = resolve;
    script.onerror = reject;
    document.body.appendChild(script);
  });
}

// Function to dynamically load external stylesheets or favicon
function loadLink(href, rel = 'stylesheet') {
  const link = document.createElement('link');
  link.href = href;
  link.rel = rel;
  document.head.appendChild(link);
}

// Create Vue app
const app = createApp(App);

// Global function to load all required external resources
app.config.globalProperties.$loadResources = async () => {
  // Load JS Plugins in sequence
  const scripts = [
    '/assets/js/jquery.js',
    '/assets/js/popper.min.js',
    '/assets/js/bootstrap.min.js',
    '/assets/js/owl.js',
    '/assets/js/wow.js',
    '/assets/js/validation.js',
    '/assets/js/jquery.fancybox.js',
    '/assets/js/appear.js',
    '/assets/js/jquery.countTo.js',
    '/assets/js/scrollbar.js',
    '/assets/js/tilt.jquery.js',
    '/assets/js/bxslider.js',
    '/assets/js/script.js' // Custom script should run last
  ];

  for (const script of scripts) {
    await loadScript(script);
  }

  // Load Google Fonts
  loadLink('https://fonts.googleapis.com/css?family=Josefin+Sans:300,300i,400,400i,600,600i,700,700i&display=swap');
  loadLink('https://fonts.googleapis.com/css?family=Muli:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');

  // Load Favicon
  loadLink('/assets/images/favicon.ico', 'icon');

  // Ensure images are correctly loaded
  await new Promise(resolve => setTimeout(resolve, 100)); // Small delay for script execution
  initImageLoad();
};

// Function to ensure images are properly displayed on navigation
function initImageLoad() {
  $('img').each(function() {
    const img = $(this);
    img.on('load', function() {
      img.show(); // Show image when loaded
    }).on('error', function() {
      img.hide(); // Hide image on error
    });

    // Trigger load event for cached images
    if (this.complete) {
      img.trigger('load');
    }
  });
}

// Ensure images reload on each route change using Vue Router's beforeEach hook
router.beforeEach((to, from, next) => {
  // Reload images on route change
  initImageLoad();
  next();
});

// Use Vue's lifecycle hooks to ensure the scripts are loaded before running custom jQuery code
app.mixin({
  mounted() {
    // Check if a custom scrollbar plugin is loaded and initialize it
    if ($.fn.mCustomScrollbar) {
      console.log('mCustomScrollbar is available!');
      $('.your-element').mCustomScrollbar();
    } else {
      console.error('mCustomScrollbar is not available. Check if it was loaded correctly.');
    }
  }
});

// Mount the Vue app and load resources
app.use(router).mount('#app');

// After mounting, call the resource loader
app.config.globalProperties.$loadResources();
