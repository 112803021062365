<template>
  <div>


      <!-- footer-style-three -->
      <footer class="footer-style-three">
        <div class="footer-top">
            <div class="pattern-layer">
                <div class="pattern-1" style="background-image: url(@/assets/images/shape/shape-60.png);"></div>
                <div class="pattern-2 wow slideInDown" data-wow-delay="00ms" data-wow-duration="3500ms" style="background-image: url(@/assets/images/shape/shape-61.png);"></div>
            </div>
            <div class="auto-container">
                <div class="widget-section">
                    <div class="row clearfix">
                        <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
                            <div class="footer-widget about-widget">
                                <div class="widget-title">
                                    <h3>About Company</h3>
                                </div>
                                <div class="widget-content">
                                    <div class="text">
                                        <p>Our goal is to empower companies to achieve top positions in their respective industries through data-driven insights and solutions. Our team focuses on excellence and integrity in every project, leading to meaningful and impactful results.</p>
                                    </div>
                                    <figure class="footer-logo"><a href="index-3.html"><img src="@/assets/images/logo5.png" alt=""></a></figure>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
                            <div class="footer-widget links-widget">
                                <div class="widget-title">
                                    <h3>Useful Links</h3>
                                </div>
                                <div class="widget-content">
                                    <ul class="clearfix">
                                        <li><a href="/">Data Science</a></li>
                                        <li><a href="/">Machine Learning</a></li>
                                        <li><a href="/">Software Engineering</a></li>
                                        <li><a href="/">Internet of Things</a></li>
                                        <li><a href="/">Artificial Intelligence</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
                            <div class="footer-widget contact-widget">
                                <div class="widget-title">
                                    <h3>Contact</h3>
                                </div>
                                <div class="widget-content">
                                    <ul class="clearfix"> 
                                        <li>
                                            <span>Phone:</span>
                                            <a href="tel:0055567890">+254 734 606 021</a>
                                        </li>
                                        <li>
                                            <span>Email:</span>
                                            <a href="info@example.com">info@nextgendata.co.ke</a>
                                        </li>
                                        <li>
                                            <span>Address:</span>
                                            Westland - Nairobi, Kenya
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
                            <div class="footer-widget subscribe-widget">
                                <div class="widget-title">
                                    <h3>Subscribe</h3>
                                </div>
                                <div class="widget-content">
                                    <div class="subscribe-form">
                                        <form @submit.prevent="submitForm">
                                            <div class="form-group">
                                            <input type="email" v-model="email" placeholder="Email address" required />
                                            <button type="submit" class="theme-btn style-six">Subscribe Us</button>
                                            </div>
                                        </form>
                                    </div>
                                    <ul class="social-links clearfix">
                                        <li><a href="index-3.html"><i class="fab fa-facebook-f"></i></a></li>
                                        <li><a href="index-3.html"><i class="fab fa-twitter"></i></a></li>
                                        <li><a href="index-3.html"><i class="fab fa-linkedin-in"></i></a></li>
                                        <li><a href="index-3.html"><i class="fab fa-google-plus-g"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="auto-container clearfix">
                <div class="copyright pull-left">
                    <p>Copyright &copy; <a href="index.html">Nextgen</a>, All Rights Reserved.</p>
                </div>
                <ul class="footer-nav pull-right">
                    <li><a href="index.html">Privacy Policy</a></li>
                    <li><a href="index.html">Terms & Conditions</a></li>
                </ul>
            </div>
        </div>
    </footer>
    <!-- footer-style-three end -->
  </div>
</template>

<script>
import axios from "axios"; // Import Axios
import Swal from "sweetalert2"; // Import SweetAlert2

export default {
  name: "FooterComponent",
  data() {
    return {
      email: "", // Bind this to the email input
    };
  },
  methods: {
    async submitForm() {
      try {
        // Send the POST request to the subscription_store route
        const response = await axios.post('https://nextgenadmins.nextgendataconsortium.co.ke/api/subscriptions/store', {
          email: this.email,
        });
        console.log(response.data); // Handle success response

        // Show SweetAlert success message
        Swal.fire({
          title: 'Subscribed!',
          text: 'You have successfully subscribed to Nextgen Data Consortium Newsletter, we will Keep you Updated!',
          icon: 'success',
          confirmButtonText: 'OK'
        });

      } catch (error) {
        console.error(error.response.data); // Handle error response

        // Show SweetAlert error message
        Swal.fire({
          title: 'Error!',
          text: 'There was an error subscribing. Please try again.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    },
  },
};
</script>


<style lang="scss" scoped></style>